import * as React from "react"
import Layout from '../../templates/layout-standard'
import Seo from '../../components/seo'
import EnrollNowNav from '../../components/nav/EnrollNow'
import UpNextCardNonStrapi from '../../components/ui/UpNextCardNonStrapi'
import SideBar from '../../components/sidebar'
import LearnMore from '../../components/learnmore'
import SpeakWithAdvisor from '../../components/ui/SpeakWithAdvisor'
import '../../assets/css/screen.css'

const AmIEligible = ({ location }) => (

	<Layout location={location} heading1="Enroll Now" heading2={['We make Medicare easy, ', <span>right from the start.</span>]}>

		<Seo title="Am I Eligible | Enroll Now" meta={[{description: 'Medicare experts at MVP have been offering Medicare plans in Vermont that are consistently highly rated by the federal Medicare program for quality, service and customer satisfaction.'}]} bodyclass="enroll subpage" />
		<EnrollNowNav />
			
		<div class="constrained">
			<div class="content-constrained">
				<div class="flexwrap_subpage">
				
					<main>
						<h3>Am I Eligible?</h3>
						
						<p>You are eligible to enroll in a UVM Health Advantage Medicare Advantage plan if you:</p>
						<ol>
							<li><b>Have Medicare Part A and Part B.</b> You must continue to pay your Part B premium, and reside in the plan&rsquo;s designated service area. Not enrolled in Part A and Part B? <a href="https://www.ssa.gov/" class="external">Contact the Social Security Administration</a>.</li>
							<li><b>Live in these Vermont counties:</b> Addison, Bennington, Caledonia, Chittenden, Essex, Franklin, Grand Isle, Lamoille, Orange, Orleans, Rutland, Washington, Windham and Windsor counties.</li>
							<li><b>Live in these New York State counties:</b> Clinton, Essex, Franklin, Hamilton, and St. Lawrence counties.</li>
						</ol>

						{/* <p>UVMHA will not refuse enrollment based on your current health status or anticipated use of health care services unless you have end-stage renal disease. If you have end-stage renal disease, current federal law does not allow you to enroll in a UVMHA Medicare Advantage plan unless you are already an MVP member or have had a successful kidney transplant.</p> */}

						<UpNextCardNonStrapi to="/enroll-now/what-happens-after-i-enroll/">What Happens After I Enroll?</UpNextCardNonStrapi>
						<LearnMore showonly="kit" />
						<SpeakWithAdvisor />
					</main>

					<SideBar location={location} />
				</div>
			</div>
		</div>
	</Layout>
)

export default AmIEligible
