import * as React from "react"
import { Link } from "gatsby"

const UpNextCardNonStrapi = (props) => {
	return (
		<div className="linkcard upnext">
			{ <Link to={props.to}>{props.children}</Link> }
		</div>
	)
}

export default UpNextCardNonStrapi;