import * as React from "react"
import { Link } from "gatsby"

const EnrollNow = () => {
	return (
		<div className="enroll-subnav">
			<div className="constrained">
				<nav aria-label="Enroll Menu">
					<ul>
						<li key={1}><Link to="/enroll-now/am-i-eligible" partiallyActive={true} activeClassName="current">Am I Eligible?</Link></li>
						<li key={2}><Link to="/enroll-now/what-happens-after-i-enroll" partiallyActive={true} activeClassName="current">What Happens After I Enroll?</Link></li>
					</ul>
				</nav>
			</div>
		</div>
	)
}

export default EnrollNow;
